<template>
  <div>
    <div>
      <b-card>
        <div class="d-flex">
          <p class="my-auto mr-auto">
            <i :class="$route.meta.iconClass"></i>
            <span
              style="font-size: 22px; color: #7f7f7f"
              class="font-weight-light"
            >
              Fiche Inscriptions :
            </span>
            <span style="font-size: 25px" class="font-weight-bold"></span>
          </p>
          <div class="row mb-3">
            <div class="col-12">
              <modal-actions
                class="mb-3"
               @close="$router.go(-1)"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="shdow col-md-4">
            <div
              class="
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
              "
            >
              <p class="h4 text-secondary my-auto mr-auto">
                Informations générales
              </p>

              <button
                href="#"
                class="mt-2 btn p-0"
                v-if="!editMode"
                :disabled="editModetype"
                @click="
                  (editMode = !editMode),
                    (editModedossiertype = true),
                    (editModedecisiontype = true)
                "
              >
                <i
                  class="mdi mdi-file-document-edit-outline"
                  style="font-size: 35px"
                  :style="{
                    color: editModetype ? 'gray' : '#007bff',
                  }"
                ></i>
              </button>

              <div v-else class="m-0 d-flex p-0" style="float: right">
                <b-button
                  @click="updateItem"
                  class="btn btn-secondary rounded-pill"
                  size="sm"
                  >Confirmer
                </b-button>
                <b-button
                  @click="
                    (editMode = !editMode),
                      (editModedossiertype = false),
                      (editModedecisiontype = false)
                  "
                  class="btn btn-secondary rounded-pill"
                  style="background-color: #adb5bd; border-color: #adb5bd"
                  size="sm"
                  >Annuler
                </b-button>
              </div>

              <hr class="bg-secondary mb-3 mt-1 w-100" />
            </div>

            <div class="row" v-if="!editMode">
              <div class="col-12">
                <div class="my-4">
                  <div class="d-flex">
                    <span class="font-weight-bold mr-3">Présentateur :</span>
                    <table-responsable
                      style="margin-top: -16px !important"
                      :responsable="selecteddata.user"
                      ><a
                        @to="
                          $router.push({
                            name: 'show-actor',
                            params: {
                              slug: selecteddata.user.slug,
                              id: selecteddata.user.id,
                            },
                          })
                        "
                      ></a
                    ></table-responsable>
                  </div>
                </div>

                <div class="my-4">
                  <span class="font-weight-bold mr-3">Session:</span>
                  <span>{{
                    selecteddata.session ? selecteddata.session.name : "n/a"
                  }}</span>
                </div>

                <div class="my-4">
                  <span class="font-weight-bold mr-3">Date :</span>
                  <span class="badge badge-danger">{{
                    moment(newInscription.date).format("DD/MM/YYYY")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Présentateur :"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <div class="font-weight-normal">
                    <v-select
                      :options="responsables"
                      placeholder="Responsable"
                      name="responsable"
                      label="text"
                      :reduce="(responsable) => responsable.value"
                      v-model="newInscription.user"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newInscription.user.$error &&
                          $v.newInscription.user.$dirty,
                      }"
                    />
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInscription.user.$dirty"
                    >
                      {{
                        !$v.newInscription.user.required
                          ? "champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Session :"
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <div class="font-weight-normal">
                    <v-select
                      :options="session"
                      placeholder="Session"
                      label="text"
                      :reduce="(sessions) => sessions.value"
                      v-model="newInscription.session"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newInscription.session.$error &&
                          $v.newInscription.session.$dirty,
                      }"
                    />
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInscription.session.$dirty"
                    >
                      {{
                        !$v.newInscription.session.required
                          ? "champ obligatoire"
                          : ""
                      }}
                    </span>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Date : "
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <!--b-form-group
                      class="font-weight-bold"
                      label="Dossier:"
                      label-cols-sm="3"
                      label-align-sm="left"
                  >
                    <b-form-select
                        :options="dossier"
                        placeholder="Dossier"
                        name="responsable"
                        label="full_name"
                        :reduce="(dossier) => dossier.id"
                        v-model="newInscription.dossier"
                        class="font-weight-normal"
                        required
                    ></b-form-select>
                  </b-form-group-->

                  <date-picker
                    v-model="newInscription.date"
                    class="col-12 p-0 mb-2 font-weight-normal"
                    placeholder="Date de début"
                    format="DD/MM/YYYY"
                    style="padding-right: 0 !important; height: 44.22px"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newInscription.date.$error &&
                        $v.newInscription.date.$dirty,
                    }"
                  />
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInscription.date.$dirty"
                  >
                    {{
                      !$v.newInscription.date.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="shdow col-md-4">
            <div
              class="
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
              "
            >
              <p class="h4 text-secondary my-auto mr-auto">Dossier</p>

              <button
                style="color: #007bff"
                class="mt-2 p-0 btn"
                @click="
                  (editModedossier = !editModedossier),
                    (editModetype = true),
                    (editModedecisiontype = true)
                "
                v-if="!editModedossier"
                :disabled="editModedossiertype"
              >
                <i
                  class="mdi mdi-file-document-edit-outline"
                  style="font-size: 35px"
                  :style="{
                    color: editModedossiertype ? 'gray' : '#007bff',
                  }"
                ></i>
              </button>
              <div v-else class="m-0 d-flex p-0" style="float: right">
                <b-button
                  @click="updatedossier"
                  class="btn btn-secondary rounded-pill"
                  size="sm"
                  >Confirmer
                </b-button>
                <b-button
                  @click="
                    (editModedossier = !editModedossier),
                      (editModetype = false),
                      (editModedecisiontype = false)
                  "
                  class="btn btn-secondary rounded-pill"
                  style="background-color: #adb5bd; border-color: #adb5bd"
                  size="sm"
                  >Annuler
                </b-button>
              </div>

              <hr class="bg-secondary mb-3 mt-1 w-100" />
            </div>

            <div class="row" v-if="!editModedossier">
              <div class="col-12">
                <div class="my-4">
                  <div class="d-flex">
                    <span class="font-weight-bold mr-3">Dossier :</span>
                    <span>
                      {{
                        selecteddata.dossier ? selecteddata.dossier.name : ""
                      }}
                    </span>
                  </div>
                </div>

                <div class="my-4">
                  <div v-if="selecteddata.dossier">
                    <div v-if="selecteddata.dossier.initiative">
                      <span class="font-weight-bold mr-3">Initiative :</span>
                      <span> </span>
                      <b-link
                        @click="
                          $router.push({
                            name: 'show-initiative',
                            params: {
                              slug: selecteddata.dossier.initiative.slug,
                              id: selecteddata.dossier.initiative.id,
                            },
                          })
                        "
                      >
                        {{ selecteddata.dossier.initiative.libelle }}
                      </b-link>
                    </div>
                    <div v-else>
                      <span class="font-weight-bold mr-3">Feuile route :</span>
                      <span></span>
                      <b-link
                        @click="
                          $router.push({
                            name: 'show-fdr',
                            params: {
                              slug: selecteddata.dossier.feuileroute.slug,
                              id: selecteddata.dossier.feuileroute.id,
                            },
                          })
                        "
                      >
                        {{
                          selecteddata.dossier
                            ? selecteddata.dossier.feuileroute.name
                            : ""
                        }}
                      </b-link>
                    </div>
                  </div>
                </div>

                <div class="my-4">
                  <span class="font-weight-bold mr-3">File :</span>
                  <p>
                    <span
                      v-if="
                        selecteddata.dossier
                          ? selecteddata.dossier.fileUrl
                          : false
                      "
                      style="color: black"
                      class="font-weight-bold"
                    >
                      <b-list-group class="mt-3">
                        <b-list-group-item>
                          <span class="">
                            <i
                              class="fa my-auto pr-1 fa-paperclip"
                              aria-hidden="true"
                            ></i>
                            <a
                              target="_blank"
                              :href="IMG_URL + selecteddata.dossier.fileUrl"
                            >
                              {{ selecteddata.dossier.filename }}</a
                            >
                          </span>
                        </b-list-group-item>
                      </b-list-group>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="dossier :"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <b-form-input
                    v-model="newDossier.dossier"
                    placeholder="Dossier"
                    :class="{
                      'is-invalid':
                        $v.newDossier.dossier.$error &&
                        $v.newDossier.dossier.$dirty,
                    }"
                  />
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newDossier.dossier.$dirty"
                  >
                    {{
                      !$v.newDossier.dossier.required ? "champ obligatoire" : ""
                    }}
                  </span>
                </b-form-group>
                <b-form-group
                  label="Type :"
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <div class="font-weight-normal">
                    <v-select
                      v-model="type"
                      :options="['Initiative', 'Feuille de route']"
                      @change="changetype"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                </b-form-group>

                <div v-if="selecteddata.dossier">
                  <div v-if="typeid">
                    <b-form-group
                      label="Initiative :"
                      class="font-weight-bold"
                      label-cols-sm="3"
                      label-align-sm="left"
                    >
                      <div class="font-weight-normal">
                        <v-select
                          :options="initiatives"
                          label="text"
                          :reduce="(initiative) => initiative.value"
                          placeholder="Initiative"
                          v-model="newDossier.Intiative"
                        />
                      </div>
                    </b-form-group>
                  </div>
                  <b-form-group
                    v-else
                    label="Feuille de route :"
                    class="font-weight-bold"
                    label-cols-sm="3"
                    label-align-sm="left"
                  >
                    <div class="font-weight-normal">
                      <v-select
                        label="text"
                        :reduce="(fdr) => fdr.value"
                        :options="feuille"
                        placeholder="Feuille de route "
                        v-model="newDossier.fueille"
                        class="font-weight-normal"
                      />
                    </div>
                  </b-form-group>
                </div>

                <b-form-group
                  label="File : "
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <!--b-form-group
                      class="font-weight-bold"
                      label="Dossier:"
                      label-cols-sm="3"
                      label-align-sm="left"
                  >
                    <b-form-select
                        :options="dossier"
                        placeholder="Dossier"
                        name="responsable"
                        label="full_name"
                        :reduce="(dossier) => dossier.id"
                        v-model="newInscription.dossier"
                        class="font-weight-normal"
                        required
                    ></b-form-select>
                  </b-form-group-->

                  <DossierFile
                    @change="filePickerChanger"
                    @click.prevent=""
                    class="font-weight-normal"
                  />
                  <span
                    v-if="
                      selecteddata.dossier
                        ? selecteddata.dossier.fileUrl
                        : false
                    "
                    style="color: black"
                    class="font-weight-bold"
                  >
                    <b-list-group class="mt-3">
                      <b-list-group-item class="m-0"
                        ><i
                          class="fa my-auto pr-1 fa-paperclip"
                          aria-hidden="true"
                        ></i>
                        <a :href="IMG_URL + selecteddata.dossier.fileUrl">
                          {{ selecteddata.dossier.filename }}</a
                        >
                      </b-list-group-item>
                    </b-list-group>
                  </span>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="shdow col-md-4">
            <div
              class="
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
              "
            >
              <p class="h4 text-secondary my-auto mr-auto">Decision</p>

              <button
                class="mt-2 btn p-0"
                v-if="!editModedecision"
                :disabled="editModedecisiontype"
                @click="
                  (editModedecision = !editModedecision),
                    (editModedossiertype = true),
                    (editModetype = true)
                "
              >
                <i
                  class="mdi mdi-file-document-edit-outline"
                  style="font-size: 35px"
                  :style="{
                    color: editModedecisiontype ? 'gray' : '#007bff',
                  }"
                ></i>
              </button>
              <div v-else class="m-0 d-flex p-0" style="float: right">
                <b-button
                  @click="updatedecision"
                  class="btn btn-secondary rounded-pill"
                  size="sm"
                  >Confirmer
                </b-button>
                <b-button
                  @click="
                    (editModedecision = !editModedecision),
                      (editModedossiertype = false),
                      (editModetype = false)
                  "
                  class="btn btn-secondary rounded-pill"
                  style="background-color: #adb5bd; border-color: #adb5bd"
                  size="sm"
                  >Annuler
                </b-button>
              </div>

              <hr class="bg-secondary mb-3 mt-1 w-100" />
            </div>

            <div class="row" v-if="!editModedecision">
              <div class="col-12">
                <div class="my-4">
                  <div class="d-flex">
                    <span class="font-weight-bold mr-3">Decision :</span>
                    <span>{{
                      selecteddata.decision
                        ? selecteddata.decision.decision
                        : ""
                    }}</span>
                  </div>
                </div>

                <div class="my-4">
                  <span class="font-weight-bold mr-3">Commentaire :</span>
                  <span>{{
                    selecteddata.decision
                      ? selecteddata.decision.commentaire
                      : ""
                  }}</span>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Décision :"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <b-form-input
                    v-model="decision"
                    :class="{
                      'is-invalid': $v.decision.$error && $v.decision.$dirty,
                    }"
                  ></b-form-input>
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.decision.$dirty"
                  >
                    {{ !$v.decision.required ? "champ obligatoire" : "" }}
                  </span>
                </b-form-group>
                <b-form-group
                  label="Commentaire :"
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <textarea
                    class="form-control W-100"
                    id="exampleFormControlTextarea1"
                    v-model="commentaire"
                    rows="9"
                  ></textarea>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/countries/data.json";
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { required } from "vuelidate/lib/validators";
import { IMG_URL } from "@/helpers/services/api";

import TableResponsable from "../../../../components/custom/TableResponsable";
import DossierFile from "@/components/custom/DossierFile";

import {
  USER_URL,
  INSCRIPTION_URL,
  INITIATIVE_URL,
  FDR_URL,
} from "@/helpers/constURL";

export default {
  components: { DossierFile, TableResponsable, ModalActions },
  data: () => ({
    editMode: false,
    editing: "",
    IMG_URL,
    editModedossiertype: false,
    editModetype: false,
    editModedecisiontype: false,
    editModedossier: false,
    date: null,
    type: null,
    commentaire: "",
    editModedecision: false,
    decision: "",
    countries: countries,
    selectedCountries: [],
    selecteddata: {},
    years: null,
    months: null,
    typeid: "",
    dossier: null,
    day: null,
    newInscription: {
      user: [],
      session: [],
      date: null,
    },
    newDossier: {
      Intiative: "",
      fueille: "",
      dossier: null,
    },
    selectedResponsable: null,
  }),
  validations: {
    decision: {
      required,
    },
    newInscription: {
      user: {
        required,
      },
      date: {
        required,
      },
      session: {
        required,
      },
    },
    newDossier: {
      dossier: {
        required,
      },
    },
  },
  methods: {
    datePickerFormat,
    filePickerChanger(files) {
      this.dossier = files;
    },
    changetype() {
      if (this.type == "Initiative") {
        this.typeid = true;
      } else if (this.type == "Feuille de route") {
        this.typeid = false;
      } else {
        this.typeid = "";
      }
    },
    updateItem() {
      this.editModedossiertype = false;
      this.editModedecisiontype = false;
      this.$v.newInscription.$touch();
      if (!this.$v.newInscription.$invalid) {
        var item = {
          id: this.$route.params.id,
          user: `/api/${USER_URL}/${this.newInscription.user}`,
          session: this.newInscription.session,
          date: moment(this.newInscription.date, "DD/MM/YYYY").format(
            "DD/MM/YYYY HH:MM:SS"
          ),
        };
        this.editMode = !this.editMode;
        if (this.$store.dispatch("manage/updateInscription", item)) {
          Swal.fire("L'inscription est mise à jour !", "", "success");
          this.selecteddata = {
            ...this.selecteddata,
            user: this.RESPONSABLES.find(
              (res) => res.id === this.newInscription.user
            ),
            session: this.SESSIONS.find(
              (ses) => ses["@id"] === this.newInscription.session
            ),
            role: this.newInscription.role,
          };
        }
      }
    },
    updatedecision() {
      this.editModedossiertype = false;
      this.editModetype = false;
      this.$v.decision.$touch();
      if (!this.$v.decision.$invalid) {
        var item = {
          id: this.INSCRIPTION.decision.id,
          decision: this.decision,
          commentaire: this.commentaire,
        };
        this.editModedecision = !this.editModedecision;
        Swal.fire("La decision est mise à jour !", "", "success");
        this.$store.dispatch("decision/updateDecision", item).then(() => {
          this.$store.dispatch(
            "manage/fetchInscription",
            this.$route.params.id
          ); // get current task
        });
      }
    },
    updatedossier() {
      this.$v.newDossier.$touch();
      if (!this.$v.newDossier.$invalid) {
        this.editModetype = false;
        this.editModedecisiontype = false;
        var dossier;
        if (this.typeid) {
          dossier = {
            id: this.selecteddata.dossier.id,
            inscription: `/api/${INSCRIPTION_URL}/${this.INSCRIPTION.id}`,
            name: this.newDossier.dossier,
            initiative: `/api/${INITIATIVE_URL}/${this.newDossier.Intiative}`,
            feuileroute: null,
          };
        } else {
          dossier = {
            id: this.selecteddata.dossier.id,
            inscription: `/api/${INSCRIPTION_URL}/${this.INSCRIPTION.id}`,
            name: this.newDossier.dossier,
            initiative: null,
            feuileroute: `/api/${FDR_URL}/${this.newDossier.fueille}`,
          };
        }
        Swal.fire("Le dossier est mise à jour !", "", "success");

        this.$store.dispatch("dossier/updateDossier", dossier).then(() => {
          this.$store.dispatch(
            "manage/fetchInscription",
            this.$route.params.id
          ); // get current task
          if (this.dossier.length) {
            this.dossier
              .map((data) => {
                this.$store.dispatch("dossier/createDossierFile", {
                  id: this.selecteddata.dossier.id,
                  file: data,
                });
              })
              .then(() => {
                this.$store.dispatch(
                  "manage/fetchInscription",
                  this.$route.params.id
                ); // get current task
              });
          } else {
            this.$store
              .dispatch("manage/fetchInscription", this.$route.params.id)
              .then(() => {
                this.$store.dispatch(
                  "manage/fetchInscription",
                  this.$route.params.id
                );
              }); // get current task
          }
        });
        this.$store.dispatch("manage/fetchInscription", this.$route.params.id); // get current task
        this.editModedossier = !this.editModedossier;
      }
    },
    convertdate(value) {
      const dateString = value;
      const changedDate = dateString.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      var date = new Date(changedDate);
      this.years = date.getFullYear();
      this.months = date.getMonth();
      this.day = date.getDate();
      return this.years;
    },
  },
  mounted() {},
  watch: {
    INSCRIPTION() {
      this.selecteddata = this.INSCRIPTION;
      this.selectedResponsable = this.INSCRIPTION.user["@id"];
      this.newInscription = {
        dossier: this.INSCRIPTION.dossier
          ? this.INSCRIPTION.dossier["@id"]
          : "",
        session: this.INSCRIPTION.session
          ? this.INSCRIPTION.session["@id"]
          : "",
        user: this.INSCRIPTION.user.id,
        date: datePickerFormat(this.INSCRIPTION.date),
      };

      this.commentaire = this.INSCRIPTION.decision
        ? this.INSCRIPTION.decision.commentaire
        : "";

      this.decision = this.INSCRIPTION.decision
        ? this.INSCRIPTION.decision.decision
        : "";
      this.newDossier.dossier = this.INSCRIPTION.dossier.name;
      this.newDossier.Intiative = 1;

      if (this.selecteddata.dossier.initiative) {
        this.type = "Initiative";
        this.typeid = true;
        this.newDossier.Intiative = this.INSCRIPTION.dossier
          ? this.INSCRIPTION.dossier.initiative.id
          : "";
      } else if (this.selecteddata.dossier.feuileroute) {
        this.type = "Feuille de route";
        this.typeid = false;
        this.newDossier.fueille = this.INSCRIPTION.dossier
          ? this.INSCRIPTION.dossier.feuileroute.id
          : "";
      } else {
        this.type = "";
      }
    },
  },

  created() {
    this.$store
      .dispatch("manage/fetchInscription", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("dossier/fetchAllDossier");
    this.$store.dispatch("role/fetchAllContributorRoles");
    this.$store.dispatch("manage/fetchAllSessions");
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("fdr/fetchAllFdrs");

    this.selecteddata = this.INSCRIPTION.user.id;
  },

  computed: {
    ...mapGetters("role", ["CONTRIBUTOR_ROLES"]),
    ...mapGetters("initiative", ["INITIATIVES"]),
    ...mapGetters("manage", ["SESSIONS"]),
    ...mapGetters("manage", ["INSCRIPTION"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("dossier", ["DOSSIER"]),
    ...mapGetters("fdr", ["FDRS"]),

    initiatives() {
      //return array of responsables
      return this.INITIATIVES.map((initiative) => ({
        value: initiative.id,
        text: initiative.libelle,
      }));
    },
    feuille() {
      //return array of responsables
      return this.FDRS.map((fdr) => ({
        value: fdr.id,
        text: fdr.name,
      }));
    },
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },

    role() {
      return this.CONTRIBUTOR_ROLES;
    },
    session() {
      var data = this.SESSIONS.map((session) => ({
        value: session["@id"],
        text: session.name,
      }));

      return data;
    },
  },
};
</script>

<style></style>
